@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.blinking {
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;
}

@keyframes blink {
    from, to {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@-moz-keyframes blink {
    from, to {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@-webkit-keyframes blink {
    from, to {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@-ms-keyframes blink {
    from, to {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@-o-keyframes blink {
    from, to {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

/* --------------------------
  Keyframes
--------------------------- */
@keyframes grow {
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(1.1);
    }
    60% {
        transform: scale(0.9);
    }
}

@keyframes turn {
    0% {
        transform: rotate(0) scale(0);
        opacity: 0;
    }
    60% {
        transform: rotate(375deg) scale(1.1);
    }
    80% {
        transform: rotate(355deg) scale(0.9);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes pulse {
    50% {
        transform: scale(1.4);
    }
}

.badge * {
    transform-origin: 50% 50%;
}

.outer,
.inner,
.inline {
    animation: grow 1s ease-out backwards;
}

.inner {
    animation-delay: 0.1s;
}

.inline {
    animation-delay: 0.15s;
}

.star {
    animation: turn 1.1s .2s ease-out backwards;
}

.star circle {
    animation: pulse .7s 1.5s;
}

.star circle:nth-of-type(2) {
    animation-delay: 1.6s;
}

.star circle:nth-of-type(3) {
    animation-delay: 1.7s;
}

.star circle:nth-of-type(4) {
    animation-delay: 1.8s;
}

.star circle:nth-of-type(5) {
    animation-delay: 1.9s;
}


.dndOutsideSourceExample div[draggable] {
    cursor: grab;
}

.isDraggable {
    cursor: grab !important;
}

